import axios from 'axios'
import useJwt from '../../auth/jwt/useJwt'
import { getConfig } from '@src/configs/config'

const bicloudAxios = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'GET, PUT,POST, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    'Content-Type': 'application/json',
  },
})

const jwtConfig = {storageTokenKeyName: 'accessToken', tokenType: 'Bearer', storageSubject: 'sub'}

// ** Request Interceptor
bicloudAxios.interceptors.request.use(
  async (config) => {
    config.baseURL = await getConfig("URL_API_BACKEND")
    const accessToken = useJwt.getToken()

    if (accessToken) {
      config.headers.Authorization = `${jwtConfig.tokenType} ${JSON.parse(accessToken)}`
      config.headers.username = localStorage.getItem(jwtConfig.storageSubject)
    }
    return config
  },
  (error) => Promise.reject(error),
)

// ** Add request/response interceptor
bicloudAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      return window.location.replace('/cambiar-contraseña')
    } else if (error.response.status >= 500 && error.response.status <= 599) {
      return window.location.replace('/error-servidor')
    } else if (error.response.status === 401) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      return window.location.replace('/login')
    } else {
      return Promise.reject({status: error.response.status, data: error.response.data})
    }
  },
)

export default bicloudAxios
